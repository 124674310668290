var StringOperation = /** @class */ (function () {
    function StringOperation() {
    }
    StringOperation.strPad = function (input, length, char, position) {
        if (char.length !== 1) {
            throw 'Invalid parameter "char" length';
        }
        if (typeof position === 'undefined') {
            position = this.LEFT;
        }
        var adding = '';
        for (var i = input.length; i < length; ++i) {
            adding += char;
        }
        if (position === this.LEFT) {
            return adding + input;
        }
        else {
            return input + adding;
        }
    };
    StringOperation.isEmpty = function (value) {
        return (typeof value === 'undefined' || null === value || '' === value);
    };
    StringOperation.strBreak = function (input, maxLength) {
        var slantString;
        if (input.length > maxLength) {
            slantString = input.substr(0, maxLength - 2);
            return slantString + '...';
        }
        else {
            return input;
        }
    };
    StringOperation.toDashCase = function (str) {
        return str.replace(/([A-Z])/g, function (g) { return "-" + g[0].toLowerCase(); });
    };
    StringOperation.toHtmlAttribute = function (value) {
        if (Array.isArray(value)) {
            var values = [];
            for (var i in value) {
                values.push('"' + this.toHtmlAttribute(value[i]) + '"');
            }
            return '[' + values.join(', ') + ']';
        }
        else if (typeof value === 'boolean') {
            return value ? 'true' : 'false';
        }
        else {
            return value.toString();
        }
    };
    StringOperation.LEFT = 0;
    StringOperation.RIGHT = 1;
    return StringOperation;
}());
export default StringOperation;
