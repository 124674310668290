import { DropdownLoader } from "@App/AutoLoad/DropdownLoader";
import { DiscussionThreadLoader } from "@App/AutoLoad/DiscussionThreadLoader";
var AutoLoader = /** @class */ (function () {
    function AutoLoader() {
        var loaders = [
            new DropdownLoader(),
            new DiscussionThreadLoader(),
        ];
        for (var i in loaders) {
            loaders[i].load(document);
        }
    }
    return AutoLoader;
}());
export { AutoLoader };
