import StringOperation from "@App/Util/StringOperation";
import DiscussionThread from "@App/Util/DiscussionThread";
var DiscussionThreadLoader = /** @class */ (function () {
    function DiscussionThreadLoader() {
    }
    DiscussionThreadLoader.prototype.load = function (html) {
        $(html).find('.discussion-thread').each(function () {
            var entityType = $(this).data('entity-type');
            var entityId = $(this).data('entity-id');
            if (StringOperation.isEmpty(entityType)) {
                throw new Error('L\'attribut "data-entity-type" est obligatoire');
            }
            if (StringOperation.isEmpty(entityId)) {
                throw new Error('L\'attribut "data-entity-id" est obligatoire');
            }
            new DiscussionThread(this, entityType, parseInt(entityId));
        });
    };
    return DiscussionThreadLoader;
}());
export { DiscussionThreadLoader };
