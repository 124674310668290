import StringOperation from "@App/Util/StringOperation";
import Tooltip from "@App/Util/Tooltip";
var Dropdown = /** @class */ (function () {
    function Dropdown(items, position, placement) {
        var _this = this;
        if (placement === undefined) {
            placement = Dropdown.PLACEMENT_BOTTOM_RIGHT;
        }
        this.$dropdown = $(document.createElement('div'))
            .addClass('dropdown')
            .css('visibility', 'hidden');
        var _loop_1 = function (item) {
            var $item;
            if (item.href !== undefined) {
                $item = $(document.createElement('a'))
                    .attr('href', item.href);
            }
            else {
                $item = $(document.createElement('span'));
            }
            if (item.data !== undefined) {
                for (var key in item.data) {
                    $item.attr('data-' + StringOperation.toDashCase(key), item.data[key]);
                }
            }
            if (item.onClick !== undefined) {
                $item.on('click', item.onClick);
            }
            if (item.icon !== undefined) {
                $item.append($(document.createElement('i'))
                    .addClass(item.icon)
                    .addClass('padding-right-5'));
            }
            if (item.tooltip !== undefined) {
                new Tooltip($item, item.tooltip);
            }
            if (item.disabled !== undefined && item.disabled) {
                $item
                    .css('color', '#aaa')
                    .css('cursor', 'not-allowed')
                    .addClass('disabled')
                    .on('click', function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                });
                ;
            }
            if (item.class !== undefined) {
                $item.addClass(item.class);
            }
            if (item.clickEvent !== undefined) {
                $item.on('click', function () {
                    $(document).trigger(item.clickEvent, [$item]);
                });
            }
            if (item.title !== undefined) {
                $item.prop('title', item.title);
            }
            $item.append(item.text);
            this_1.$dropdown.append($item);
        };
        var this_1 = this;
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var item = items_1[_i];
            _loop_1(item);
        }
        var $body = $(document).find('body');
        $body.append(this.$dropdown);
        position = this.getTopLeftCornerPosition(position, placement, this.$dropdown.width(), this.$dropdown.height());
        this.$dropdown.css('top', position.top);
        this.$dropdown.css('left', position.left);
        this.$dropdown.css('visibility', 'visible');
        window.setTimeout(function () {
            $body.on('click', _this.onClick.bind(_this));
        }, 1);
    }
    Dropdown.prototype.getTopLeftCornerPosition = function (position, placement, width, height) {
        switch (placement) {
            case Dropdown.PLACEMENT_TOP:
                return {
                    top: position.top - height,
                    left: position.left - width / 2,
                };
            case Dropdown.PLACEMENT_TOP_LEFT:
                return {
                    top: position.top - height,
                    left: position.left - width,
                };
            case Dropdown.PLACEMENT_TOP_RIGHT:
                return {
                    top: position.top - height,
                    left: position.left,
                };
            case Dropdown.PLACEMENT_BOTTOM_LEFT:
                return {
                    top: position.top,
                    left: position.left - width,
                };
            case Dropdown.PLACEMENT_BOTTOM_RIGHT:
                return {
                    top: position.top,
                    left: position.left,
                };
        }
    };
    Dropdown.prototype.onClick = function (e) {
        this.$dropdown.remove();
        $(document).find('body').off('click', this.onClick);
    };
    Dropdown.PLACEMENT_TOP = 'top';
    Dropdown.PLACEMENT_TOP_LEFT = 'top_left';
    Dropdown.PLACEMENT_TOP_RIGHT = 'top_right';
    Dropdown.PLACEMENT_BOTTOM_LEFT = 'bottom_left';
    Dropdown.PLACEMENT_BOTTOM_RIGHT = 'bottom_right';
    return Dropdown;
}());
export default Dropdown;
