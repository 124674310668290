import * as _ from "@App/Util/CommonJS";
import { Routing } from "@App/Util/Routing";
import DateTimeOperation from "@App/Util/DateTimeOperation";
import Dialog from "@App/Util/Dialog";
import Dropdown from "@App/Util/Dropdown";
import Tooltip from "@App/Util/Tooltip";
var DiscussionThread = /** @class */ (function () {
    function DiscussionThread(wrapper, entityType, entityId) {
        var _this = this;
        this.$loader = null;
        this.maxResults = 50;
        this.page = 1;
        this.height = null;
        this.$wrapper = $(wrapper);
        this.entityType = entityType;
        this.entityId = entityId;
        this.height = this.$wrapper.attr('data-height');
        if (this.height === undefined) {
            this.height = null;
        }
        this.$wrapper.html('');
        this.$wrapper
            .append(this.createChatBodyNode())
            .append(this.createChatFooterNode());
        this.respondToVisibility();
        this.dataLoader = new _.DataLoader([
            {
                name: 'payload',
                source: {
                    type: 'GET',
                    url: Routing.generate('commun_discussion_thread_get_discussion_thread_messages_ajax'),
                    data: function () {
                        return {
                            entityType: _this.entityType,
                            entityId: _this.entityId,
                            maxResults: _this.maxResults,
                            page: _this.page,
                        };
                    },
                },
                refresh: function (data) { return _this.refresh(data); },
                triggers: [
                    {
                        element: $(document),
                        event: 'ready',
                        reload: true
                    }
                ],
                preload: function () {
                    _this.toggleLoader(true);
                },
                locks: [
                    this.$body,
                ],
            }
        ]);
    }
    DiscussionThread.prototype.refresh = function (data) {
        if (data.discussionThreadMessages.length === 0) {
            this.$body.html($(document.createElement('span'))
                .append('Aucun message pour le moment')
                .addClass('text-italic'));
        }
        else {
            var messages = [];
            for (var _i = 0, _a = data.discussionThreadMessages; _i < _a.length; _i++) {
                var discussionThreadMessage = _a[_i];
                var author = data.dictionary.authors[discussionThreadMessage.authorId].nom;
                var createdAt = new Date(discussionThreadMessage.createdAt);
                var updatedAt = discussionThreadMessage.updatedAt !== null ? new Date(discussionThreadMessage.updatedAt) : null;
                var id = discussionThreadMessage.id;
                var message = discussionThreadMessage.content;
                var isAuthor = data.dictionary !== null && discussionThreadMessage.authorId === data.dictionary.userId;
                messages.push(this.createMessageNode(id, isAuthor, author, message, createdAt, updatedAt));
            }
            this.$body.html(messages);
            this.scrollDown();
        }
        this.toggleLoader(false);
        this.$wrapper.trigger('discussion-thread:refreshed', [data.discussionThreadMessages]);
    };
    DiscussionThread.prototype.createChatBodyNode = function () {
        this.$body = $(document.createElement('div'))
            .addClass('chat-body');
        if (this.height !== null) {
            this.$body.css('maxHeight', this.height + 'px');
        }
        return this.$body;
    };
    DiscussionThread.prototype.createMessageNode = function (messageId, isAuthor, author, message, createdAt, updatedAt) {
        var _this = this;
        if (isAuthor === void 0) { isAuthor = false; }
        var $messageInfo = $(document.createElement('div'))
            .addClass('message-info')
            .append($(document.createElement('h4'))
            .text(author))
            .append($(document.createElement('h5'))
            .append($(document.createElement('i'))
            .addClass('fa fa-clock'))
            .append(DateTimeOperation.dateTimeToStringFr(createdAt)));
        if (isAuthor) {
            $messageInfo.prepend($(document.createElement('i'))
                .addClass('fa fa-cog discussion-thread-message-menu')
                .on('click', function (e) {
                new Dropdown([
                    {
                        text: 'Supprimer le message',
                        icon: 'fa fa-trash',
                        onClick: function () {
                            _this.deleteMessage(messageId);
                        },
                    },
                    {
                        text: 'Modifier le message',
                        icon: 'fa fa-pencil',
                        onClick: function () {
                            _this.editMessage(messageId);
                        },
                    },
                ], {
                    top: e.pageY,
                    left: e.pageX,
                }, Dropdown.PLACEMENT_TOP_LEFT);
            }));
        }
        var $message = $(document.createElement('div'))
            .addClass('message')
            .addClass(isAuthor ? 'my-message' : 'info')
            .attr('data-message-id', messageId)
            .append($(document.createElement('div'))
            .addClass('message-author-circle')
            .text(this.getAbbreviation(author)))
            .append($(document.createElement('div'))
            .addClass('message-body')
            .append($messageInfo)
            .append($(document.createElement('div'))
            .addClass('clearfix'))
            .append($(document.createElement('hr')))
            .append($(document.createElement('div'))
            .addClass('message-text')
            .html(this.cleanMessageText(message))))
            .append(document.createElement('br'));
        if (null !== updatedAt) {
            var $span = $(document.createElement('span'))
                .text('(modifié)')
                .addClass('updated-date-time');
            var $tooltipContent = $(document.createElement('span'))
                .text(DateTimeOperation.dateTimeToStringFr(updatedAt))
                .css('color', '#000');
            new Tooltip($span, $tooltipContent, Tooltip.PLACEMENT_TOP, $message);
            $messageInfo.find('h5').append($span);
        }
        return $message;
    };
    DiscussionThread.prototype.createChatFooterNode = function () {
        var _this = this;
        this.$textarea = $(document.createElement('textarea'))
            .addClass('send-message-text')
            .on('keyup', function (e) {
            if (e.keyCode === 13 && !e.shiftKey) {
                var text = _this.$textarea.val();
                _this.$textarea.val('');
                _this.send(text);
            }
        });
        return $(document.createElement('div'))
            .addClass('chat-footer')
            .append(this.$textarea)
            .append($(document.createElement('button'))
            .attr('type', 'button')
            .addClass('send-message-button btn-primary')
            .on('click', function (e) {
            var text = _this.$textarea.val();
            _this.$textarea.val('');
            _this.send(text);
        })
            .append($(document.createElement('i'))
            .addClass('fa fa-send')));
    };
    DiscussionThread.prototype.send = function (text) {
        var _this = this;
        this.toggleLoader(true);
        $.ajax({
            type: 'POST',
            url: Routing.generate('commun_discussion_thread_create_discussion_thread_message_ajax'),
            data: {
                entityType: this.entityType,
                entityId: this.entityId,
                content: text,
            }
        }).done(function (res) {
            _this.dataLoader.reload('payload');
        }).fail(function (err) {
            new Dialog('Erreur', 'Impossible d\'enregistrer le message. Veuillez réessayer plus tard.');
            _this.$textarea.val(text);
            _this.toggleLoader(false);
        });
    };
    DiscussionThread.prototype.toggleLoader = function (show) {
        if (show === void 0) { show = true; }
        if (show) {
            if (this.$loader === null) {
                var height = this.$body.outerHeight();
                if (height < 200) {
                    height = 200;
                }
                this.$loader = $(document.createElement('div'))
                    .addClass('lock-loader background-loader')
                    .css({
                    position: 'absolute',
                    top: this.$body[0].offsetTop,
                    left: this.$body[0].offsetLeft,
                    height: height,
                    width: '100%',
                    opacity: 0.5,
                    backgroundColor: '#fff',
                });
                this.$loader.insertAfter(this.$body);
            }
        }
        else if (this.$loader !== null) {
            this.$loader.remove();
            this.$loader = null;
        }
    };
    DiscussionThread.prototype.scrollDown = function () {
        this.$body.scrollTop(this.$body[0].scrollHeight);
    };
    DiscussionThread.prototype.respondToVisibility = function () {
        var _this = this;
        if (typeof IntersectionObserver !== 'undefined') {
            var options = {
                root: document.documentElement,
            };
            var observer = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.intersectionRatio > 0) {
                        _this.scrollDown();
                    }
                });
            }, options);
            observer.observe(this.$body[0]);
        }
    };
    DiscussionThread.prototype.getAbbreviation = function (name) {
        if (name.indexOf(' ') !== -1) {
            var parts = name.split(' ');
            return (parts[0][0] + parts[1][0]).toUpperCase();
        }
        else {
            return name[0].toUpperCase() + name[1];
        }
    };
    DiscussionThread.prototype.deleteMessage = function (id) {
        var _this = this;
        this.toggleLoader(true);
        $.ajax({
            type: 'POST',
            url: Routing.generate('commun_discussion_thread_delete_discussion_thread_message_ajax'),
            data: {
                id: id,
            }
        }).done(function (res) {
            _this.dataLoader.reload('payload');
        }).fail(function (err) {
            new Dialog('Erreur', 'Impossible de supprimer le message. Veuillez réessayer plus tard.');
            _this.toggleLoader(false);
        });
    };
    DiscussionThread.prototype.editMessage = function (id, text) {
        var _this = this;
        if (text === void 0) { text = null; }
        if (null === text) {
            var $message_1 = this.getMessageNode(id);
            var $messageText = $message_1.find('.message-text');
            $messageText.html('');
            $message_1.find('.message-text')
                .append($(document.createElement('textarea'))
                .addClass('edit-message-text'))
                .append($(document.createElement('div'))
                .addClass('pull-right')
                .append($(document.createElement('button'))
                .attr('type', 'button')
                .addClass('btn btn-small btn-primary edit-message-button')
                .on('click', function (e) {
                text = $message_1.find('.message-text textarea').first().val();
                _this.editMessage(id, text);
            })
                .append($(document.createElement('i'))
                .addClass('fa fa-check'))
                .append(' valider'))
                .append($(document.createElement('button'))
                .attr('type', 'button')
                .addClass('btn btn-small btn-danger edit-message-button')
                .on('click', function (e) {
                _this.cancelEditing(id);
            })
                .append($(document.createElement('i'))
                .addClass('fa fa-ban'))
                .append(' annuler')))
                .append($(document.createElement('div'))
                .addClass('clearfix'));
            $message_1.addClass('editing');
            $message_1.find('.message-text textarea').first()
                .val(this.getMessage(id).content)
                .focus();
        }
        else {
            this.toggleLoader(true);
            $.ajax({
                type: 'POST',
                url: Routing.generate('commun_discussion_thread_update_discussion_thread_message_ajax'),
                data: {
                    id: id,
                    content: text,
                }
            }).done(function (res) {
                _this.dataLoader.reload('payload');
            }).fail(function (err) {
                new Dialog('Erreur', 'Impossible d\'enregistrer le message. Veuillez réessayer plus tard.');
                _this.toggleLoader(false);
            });
        }
    };
    DiscussionThread.prototype.cancelEditing = function (id) {
        var $message = this.getMessageNode(id);
        var message = this.getMessage(id);
        $message.find('.message-text').html(this.cleanMessageText(message.content));
    };
    DiscussionThread.prototype.getMessageNode = function (id) {
        return this.$body.find('[data-message-id=' + id + ']');
    };
    DiscussionThread.prototype.getMessage = function (id) {
        var messages = this.dataLoader.get('payload').discussionThreadMessages;
        for (var _i = 0, messages_1 = messages; _i < messages_1.length; _i++) {
            var message = messages_1[_i];
            if (message.id === id) {
                return message;
            }
        }
    };
    DiscussionThread.prototype.cleanMessageText = function (html) {
        var container = document.createElement('div');
        var text = document.createTextNode(html);
        container.appendChild(text);
        text = container.innerHTML;
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    };
    return DiscussionThread;
}());
export default DiscussionThread;
