var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var Dialog = /** @class */ (function () {
    function Dialog(title, body, buttons, options) {
        var _this = this;
        this.options = undefined !== options ? options : {};
        if (this.options.width === undefined) {
            this.options.width = 460;
        }
        if (typeof body === 'string') {
            body.replace("\r\n", '<br>');
        }
        this.$container = $(document.createElement('div'))
            .append(body)
            .attr('title', title);
        $('body').append(this.$container);
        if (typeof buttons === 'undefined') {
            buttons = {
                Ok: function () {
                    _this.close();
                }
            };
        }
        else {
            var keys = Object.keys(buttons);
            var _loop_1 = function (i) {
                var key = keys[i];
                var callback = buttons[key];
                buttons[key] = function () {
                    if (callback() !== false) {
                        _this.close();
                    }
                };
            };
            for (var i in keys) {
                _loop_1(i);
            }
        }
        this.$container.dialog(__assign({}, this.options, { buttons: buttons, close: function () {
                _this.$container.remove();
                _this.$container = null;
                if (undefined !== _this.options.onClose) {
                    _this.options.onClose();
                }
            } }));
    }
    Dialog.prototype.close = function () {
        if (undefined !== this.options.onClose) {
            delete this.options.onClose;
        }
        this.$container.dialog('close');
    };
    return Dialog;
}());
export default Dialog;
