import Dropdown from "@App/Util/Dropdown";
var DropdownLoader = /** @class */ (function () {
    function DropdownLoader() {
    }
    DropdownLoader.prototype.load = function (html) {
        $(html).find('ul.dropdown-actions').each(function () {
            var items = [];
            $(this).find('li').each(function () {
                var item = {
                    text: $(this).html(),
                    data: $(this).data(),
                };
                if ($(this).data('href') !== undefined) {
                    item.href = $(this).data('href');
                }
                if ($(this).data('icon') !== undefined) {
                    item.icon = $(this).data('icon');
                }
                if ($(this).data('tooltip') !== undefined) {
                    item.tooltip = $(this).data('tooltip');
                }
                if ($(this).data('disabled') !== undefined) {
                    item.disabled = true;
                }
                if ($(this).data('clickEvent') !== undefined) {
                    item.clickEvent = $(this).data('clickEvent');
                }
                if ($(this).attr('class')) {
                    item.class = $(this).attr('class');
                }
                items.push(item);
            });
            var dataPlacement = Dropdown.PLACEMENT_BOTTOM_LEFT;
            if ($(this).attr('data-placement')) {
                dataPlacement = $(this).data('placement');
            }
            var $toggleButton = $(document.createElement('i'))
                .attr('title', 'Actions')
                .addClass('fa fa-bars pointer btn-actions')
                .on('click', function (e) {
                new Dropdown(items, {
                    top: e.clientY,
                    left: e.clientX
                }, dataPlacement);
            });
            $(this).replaceWith($toggleButton);
        });
    };
    return DropdownLoader;
}());
export { DropdownLoader };
