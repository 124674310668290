import StringOperation from "@App/Util/StringOperation";
var DateTimeOperation = /** @class */ (function () {
    function DateTimeOperation() {
    }
    DateTimeOperation.dateTimeToStringStd = function (value) {
        if (typeof value === 'undefined') {
            throw 'Impossible de convertir une date indéfinie au format standard';
        }
        if (!StringOperation.isEmpty(value)) {
            var year = value.getFullYear().toString();
            var month = StringOperation.strPad((value.getMonth() + 1).toString(), 2, '0', StringOperation.LEFT);
            var day = StringOperation.strPad(value.getDate().toString(), 2, '0', StringOperation.LEFT);
            var hour = StringOperation.strPad(value.getHours().toString(), 2, '0', StringOperation.LEFT);
            var minute = StringOperation.strPad(value.getMinutes().toString(), 2, '0', StringOperation.LEFT);
            var second = StringOperation.strPad(value.getSeconds().toString(), 2, '0', StringOperation.LEFT);
            return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
        }
    };
    DateTimeOperation.dateTimeToStringFr = function (value) {
        if (typeof value === 'undefined') {
            throw 'Impossible de convertir une date indéfinie au format fr';
        }
        if (!StringOperation.isEmpty(value)) {
            var year = value.getFullYear().toString();
            var month = StringOperation.strPad((value.getMonth() + 1).toString(), 2, '0', StringOperation.LEFT);
            var day = StringOperation.strPad(value.getDate().toString(), 2, '0', StringOperation.LEFT);
            var hour = StringOperation.strPad(value.getHours().toString(), 2, '0', StringOperation.LEFT);
            var minute = StringOperation.strPad(value.getMinutes().toString(), 2, '0', StringOperation.LEFT);
            var second = StringOperation.strPad(value.getSeconds().toString(), 2, '0', StringOperation.LEFT);
            return day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second;
        }
    };
    DateTimeOperation.fuckedUpDateToRealDateTimeObject = function (date, hour, minute) {
        if (date != null) {
            date.setHours(hour);
            date.setMinutes(minute);
        }
        return date;
    };
    return DateTimeOperation;
}());
export default DateTimeOperation;
