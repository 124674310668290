var Tooltip = /** @class */ (function () {
    function Tooltip(element, content, placement, container) {
        if (placement === void 0) { placement = Tooltip.PLACEMENT_TOP; }
        if (container === void 0) { container = null; }
        this.$element = $(element);
        this.$element.popover({
            trigger: 'hover',
            content: content,
            placement: placement,
            html: true,
            container: container,
        });
    }
    Tooltip.prototype.remove = function () {
        this.$element.popover('destroy');
    };
    Tooltip.PLACEMENT_TOP = 'top';
    Tooltip.PLACEMENT_BOTTOM = 'bottom';
    Tooltip.PLACEMENT_LEFT = 'left';
    Tooltip.PLACEMENT_RIGHT = 'right';
    return Tooltip;
}());
export default Tooltip;
